<template>
  <div class="full-height__ container_auth">
    <div class="d-flex justify-center mt-3">
      <img
        src="../../../assets/img/logo-tanoto.png"
        class="mr-2"
        alt="logo-tanoto-foundation"
        height="40px"
        style="z-index: 1"
      />
      <img
        src="../../../assets/img/logo-teladan.png"
        alt="logo-teladan"
        height="40px"
        style="z-index: 1"
      />
    </div>
    <div
      class="container_temp d-flex flex-column align-center justify-center pa-3"
    >
      <v-card class="temp__" elevation="5">
        <div class="py-7 px-7">
          <div class="d-flex justify-center">
            <img src="../../../assets/img/resetPwd.png" height="150px" alt="" />
          </div>
          <h2 style="color: #006341">Create New Password</h2>
          <p class="grey--text">
            Your new password must be different from previously used passwords
          </p>
          <v-alert
            :value="response ? true : false"
            v-if="response.email"
            dense
            text
            transition="scale-transition"
            class="rounded_alert py-2 px-7"
            type="error"
          >
            <small>{{ response.email }}</small>
          </v-alert>
          <v-alert
            v-if="errMsg"
            :value="errMsg ? true : false"
            dense
            text
            transition="scale-transition"
            class="rounded_alert py-2 px-7"
            type="error"
          >
            <small>{{ errMsg.errors.password[0] }}</small>
          </v-alert>
          <form @submit.prevent="resetPwd">
            <v-text-field
              dense
              v-model="password"
              :error-messages="PasswordErr"
              label="Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              counter
              @click:append="show = !show"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            ></v-text-field>
            <v-text-field
              dense
              v-model="repeatPassword"
              :error-messages="repeatPasswordErr"
              label="Repeat Password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              counter
              @click:append="show2 = !show2"
              @input="$v.repeatPassword.$touch()"
              @blur="$v.repeatPassword.$touch()"
            ></v-text-field>
            <div class="mt-5">
              <v-btn
                class="text-capitalize"
                type="resetPwd"
                block
                v-if="!loading"
                color="#006341"
                dark
              >
                Send
              </v-btn>
              <v-btn
                class="mb-3 text-capitalize"
                v-if="loading"
                block
                color="#006341"
                dark
              >
                Loading..
              </v-btn>
            </div>
          </form>
          <div class="pa-1">
            <SuccessReset v-bind:dialogReset="dialogReset" />
          </div>
        </div>
        <v-card-actions class="footer">
          <section class="p-1 d-flex justify-center full_width__">
            <small class="white--text">
              <router-link class="white--text" to="/login"
                >Back to login page</router-link
              ></small
            >
          </section>
        </v-card-actions>
      </v-card>
    </div>
    <div class="temp-boba d-flex">
      <div class="boba1"></div>
      <div class="boba2"></div>
      <div class="boba3"></div>
      <div class="boba4"></div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import SuccessReset from "./successReset.vue";

export default {
  components: { SuccessReset },
  name: "Login",
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(8) },
    repeatPassword: { required, sameAsPassword: sameAs("password") },
  },
  computed: {
    ...mapState({
      errMsg: (state) => state.auth.errMsg,
      response: (state) => state.auth.response,
    }),
    PasswordErr() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    repeatPasswordErr() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.sameAsPassword &&
        errors.push("kata sandi tidak sama!");
      !this.$v.repeatPassword.required &&
        errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    this.$store.commit("auth/RESET_RESPONSE");
    next();
  },
  data() {
    return {
      password: "",
      repeatPassword: "",
      errAlert: false,
      loading: false,
      err: null,
      show: false,
      show2: false,
      dialogReset: false,
    };
  },
  mounted() {
  },
  methods: {
    resetPwd() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          token: this.$route.params.token,
          email: this.$route.params.email,
          password: this.password,
          password_confirmation: this.repeatPassword,
        };
        this.loading = true;
        this.$store
          .dispatch("auth/resetPassword", data)
          .then((data) => {
            if (data) {
              this.dialogReset = true;
            }
            this.$store.commit("auth/RESET_ERROR");
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.container_auth {
  height: 100vh;
  width: 100%;
  background: #006341;
  overflow: hidden;
}
.container_temp {
  top: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 2 !important;
}
.temp__ {
  width: 30%;
  height: max-content;
  background: white;
  border-radius: 20px !important;
  overflow: hidden;
}
.temp_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 57%;
}
.covers__ {
  background: #a2aaad;
  width: 43%;
  border-radius: 0 !important;
}
.footer {
  background: #b3a369;
  border-radius: 0 !important;
}
.temp-boba {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.rounded_alert {
  border-radius: 20px !important;
}
.boba1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 6s;
  transform-origin: bottom;
  animation-delay: 1s;
}
.boba2 {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 7s;
  transform-origin: bottom;
  animation-delay: 2s;
}
.boba3 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba1 infinite ease-in-out alternate 5s;
  transform-origin: bottom;
  animation-delay: 0.5s;
}
.boba4 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #005f3e;
  animation: boba4 infinite ease-in-out alternate 3s;
  transform-origin: bottom;
  animation-delay: 6s;
}

@keyframes boba1 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
@keyframes boba4 {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(200%, 0%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
</style>
