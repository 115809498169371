<template>
  <v-row justify="start">
    <v-dialog v-model="dialogReset" persistent max-width="500px">
      <v-card class="rounded-max pa-3">
        <h2 class="text-capitalize text-center" style="color: #006341">
          password has been changed
        </h2>
        <v-container>
          <div class="d-flex justify-center">
            <img
              src="../../../assets/img/resetSuccess.png"
              height="150px"
              alt=""
            />
          </div>
          <div class="d-flex justify-center">
            <v-btn
              color="#006341"
              depressed
              dark
              to="/login"
              style="width: 70%"
            >
              <p class="ma-0">Login</p></v-btn
            >
          </div>
        </v-container>
        <v-card-actions class="d-flex justify-center">
          <small class="text-center">
            Will be automatically redirected to the login page within
            {{ counting }} seconds.
          </small>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "successreset",
  data() {
    return {
      loading: false,
      counting: null,
    };
  },
  watch: {
    dialogReset(val) {
      if (val) {
        this.countDown();
      }
    },
  },
  props: ["dialogReset"],

  methods: {
    countDown() {
      let sec = 10;
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          clearInterval(min);
          this.$router.push(`/login`);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
